<template>
  <div class="tbdod new-bigmianban">
    <!-- <div id="particles-js" class="particles"></div> -->
    <!-- 顶部横栏 -->
    <div class="topBg">
      <div style="position: absolute;">
        <el-image :src="require('@/assets/newPanel/topTitleBg.png')" fit="cover" />
      </div>
      <div class="topBgBoxCent">
        <div class="top">
          <div v-if="logoUrl && windowHost">
            <el-image v-if="logoUrl" style="width: 38px; height: 38px;margin: 0 8px;" :src="logoUrl"
              fit="cover" />
          </div>
          <div v-else>
            <el-image v-if="$store.state.isZx" style="width: 38px; height: 38px;margin: 0 8px;"
              :src="require('@/assets/zhongxin.png')" fit="cover" />
          </div>
          <h2 class="title">
            <span v-if="platformName&&!$store.state.isZx && windowHost">
              <span>{{platformName}}</span>
            </span>
            <span v-else>
              <span v-if="$store.state.isZx && !windowHost">中信数字化电梯物联网监管系统</span>
              <span v-else>数字化电梯物联网安全监管系统</span>
            </span>
          </h2>
        </div>
      </div>
      <div class="topBgBox1" style="position: absolute; right: 0; top: 20px">
        <div class="top-right">
          <div @click="handleFullScreen" :class="[fullscreen?'':'close-full-Screen','full-Screen']"></div>
          <div class="top-time-date">{{timeVal[0]}}&nbsp;&nbsp;{{timeVal[1]}}&nbsp;&nbsp;{{day}}</div>
        </div>
      </div>
    </div>
    <div class="map-container" @mouseleave="mouseleave">
      <!-- echarts中国地图 -->
      <div v-show="!showMapGD" class="echarts-container">
        <!-- <div v-show="!showMapGD" class="echarts-container"> -->
        <div id="myChart" ref="myChart"></div>
        <div class="echarts-history">
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item v-for="item in echartsHistory" :key="item.regionData.adcode" :to="item.regionData.adcode" @click.native="goRegion(item)">
							{{item.regionData.name}}
						</el-breadcrumb-item>
					</el-breadcrumb> -->
        </div>
      </div>
      <!-- 高德地图 -->
      <div v-show="showMapGD" class="mapGD-container">
        <div id="mapGD"></div>
        <!-- <el-button @click="backEcharts" class="back-echarts" type="primary">返回</el-button> -->
      </div>
      <div class="map-icon-box">
        <el-image class="map-icon" @click="mapRest" :src="require('@/assets/newPanel/reset.png')" fit="cover" />
        <el-image class="map-icon" @click="mapBack" :src="require('@/assets/newPanel/back.png')" fit="cover" />
        <el-image class="map-icon" @click="mapZoomAdd" :src="require('@/assets/newPanel/add.png')" fit="cover" />
        <el-image class="map-icon" @click="mapZoomReduce" :src="require('@/assets/newPanel/reduce.png')" fit="cover" />
      </div>
    </div>

    <!-- 左图表 -->
    <div class="left-box">
      <div class="left-box-a">
        <div style="padding-right: 14%; height: 100%">
          <div class="box-img" style="margin-top: 5px; margin-bottom: -2px;">
            <span>{{leftTitleA}}</span>
          </div>
          <el-image class="titleBg" :src="require('@/assets/newPanel/msgTit.png')" fit="cover" />
          <div style="height: 90%">
            <div class="chart" @click="openRisk" style="width: 420px; height: 100%; display: flex; align-items: center;"
              id="left-box-a"></div>
          </div>
        </div>
      </div>
      <div class="left-box-b">
        <div style="padding-right: 14%; height: 100%">
          <div class="title-container">
            <div class="box-img">
              <span>{{leftTitleB}}</span>
            </div>
            <date-btns ref="leftBoxB" @change="({time,dateVal})=>changefnStatistics(dateVal)"></date-btns>
          </div>
          <el-image class="titleBg" :src="require('@/assets/newPanel/msgTit.png')" fit="cover" />
          <div style="height: 80%">
            <div v-show="leftEchartsBShow == 1" class="chart" style="width: 420px; height: 100%" id="left-box-b1"
              :key="1"></div>
            <div v-show="leftEchartsBShow == 2" class="chart" style="width: 420px; height: 100%" id="left-box-b2"
              :key="2"></div>
            <div v-show="leftEchartsBShow == 3" class="chart" style="width: 420px; height: 100%" id="left-box-b3"
              :key="3"></div>
          </div>
        </div>
      </div>
      <div class="left-box-c">
        <div style="padding-right: 14%; height: 100%">
          <div class="title-container">
            <div class="box-img">
              <span>{{leftTitleC}}</span>
            </div>
            <date-btns ref="leftBoxC" @change="({time,dateVal})=>changeEventStatisticsInit(dateVal)"></date-btns>
          </div>
          <el-image class="titleBg" :src="require('@/assets/newPanel/msgTit.png')" fit="cover" />

          <div class="leftBoxCBox" style="height: 80%">
            <div class="leftBoxCImg" @click="leftBoxOpen('报警数')">
              <el-image :src="require('@/assets/newPanel/sosNum.png')" fit="cover" />
              <div class="leftBoxCNum">
                <div style="font-size: 26px; color: #B4FFFE">{{eventNum.gaojinshu}}</div>
                <div>报警数</div>
              </div>
            </div>
            <div class="leftBoxCImg" @click="leftBoxOpen('故障数')">
              <el-image :src="require('@/assets/newPanel/faultNum.png')" fit="cover" />
              <div class="leftBoxCNum">
                <div style="font-size: 26px; color: #B4FFFE">{{eventNum.guzhangshu}}</div>
                <div>故障数</div>
              </div>
            </div>
            <div class="leftBoxCImg" @click="leftBoxOpen('困人数')">
              <el-image :src="require('@/assets/newPanel/trappedNum.png')" fit="cover" />
              <div class="leftBoxCNum">
                <div style="font-size: 26px; color: #B4FFFE">{{eventNum.kunren}}</div>
                <div>困人数</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右图表 -->
    <div class="right-box">
      <div class="right-box-a">
        <div style="padding-left: 17%; height: 100%">
          <div class="box-img">
            <span>{{rightTitleA}}</span>
          </div>
          <el-image class="titleBg" :src="require('@/assets/newPanel/msgTit.png')" fit="cover" />
          <div class="chart" style="margin-top: 0" id="right-box-a" @click="($event)=>openseamlessItem($event,1)"
            @mouseover="($event)=>mouseSeamlessItem($event,true)" @mouseout="($event)=>mouseSeamlessItem($event,false)">
            <vue-seamless-scroll v-if="rescueList.length>0" :data="rescueList" :class-option="rescueClassOption"
              class="right-a-list-container">
              <div class="right-a-list">
                <div :data-obj="JSON.stringify(item)" :data-i="index"
                  :class="[rescueStatusObj[item.status].class,'right-a-item seamless-row']"
                  v-for="(item,index) in rescueList" :key="item.id">
                  <div class="sos"></div>
                  <div class="right-a-title-container" style="color: #fff" :title="item.eleName">
                    <div class="right-a-title ellipsis">{{ item.eleName }}</div>
                    <div class="right-a-date">{{ item.createTime }}</div>
                  </div>
                  <div class="right-a-time-container">
                    <div class="right-a-status">{{ rescueStatusObj[item.status].value }}</div>
                    <div class="right-a-time">{{ item.time }}</div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
            <div v-else class="null-list">
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <div class="right-box-b">
        <div style="padding-left: 17%; height: 100%">
          <div class="box-img">
            <span>{{rightTitleB}}</span>
          </div>
          <el-image class="titleBg" :src="require('@/assets/newPanel/msgTit.png')" fit="cover" />
          <div class="chart" style="margin-top: 0" id="right-box-b" @click="($event)=>openseamlessItem($event,2)"
            @mouseover="($event)=>mouseSeamlessItem($event,true)" @mouseout="($event)=>mouseSeamlessItem($event,false)">
            <vue-seamless-scroll v-if="faultList.length>0" :data="faultList" :class-option="faultClassOption"
              class="right-b-list-container">
              <div class="right-b-list">
                <div :data-obj="JSON.stringify(item)" :data-i="index" :class="['right-b-item seamless-row']"
                  v-for="(item,index) in faultList" :key="index">
                  <div class="right-b-list-left">
                    <img class="right-b-img" style="flex: 1" :src="require('@/assets/newPanel/fault.png')" />
                    <div class="right-b-status" style="flex: 3;min-width: 50px;">{{ item.value }}</div>
                  </div>
                  <div class="right-b-title-container" :title="item.eleName">
                    <div class="right-b-title ellipsis">{{ item.eleName }}</div>
                    <div class="right-b-date">{{ item.sosTime }}</div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
            <div v-else class="null-list">
              暂无数据
            </div>
          </div>
        </div>
      </div>
      <div class="right-box-c">
        <div style="padding-left: 17%; height: 100%">
          <div class="title-container">
            <div class="box-img">
              <span>{{rightTitleC}}</span>
            </div>
            <date-btns ref="rightBoxC" @change="({time,dateVal})=>changeAnalysisInit(dateVal)"></date-btns>
          </div>
          <el-image class="titleBg" :src="require('@/assets/newPanel/msgTit.png')" fit="cover" />
          <div v-if="rightEchartsCShowData" style="height: 75%">
            <div v-show="rightEchartsCShow == 1" class="chart" style="width: 400px; height: 100%" id="right-box-c1"
              :key="1"></div>
            <div v-show="rightEchartsCShow == 2" class="chart" style="width: 400px; height: 100%" id="right-box-c2"
              :key="2"></div>
            <div v-show="rightEchartsCShow == 3" class="chart" style="width: 400px; height: 100%" id="right-box-c3"
              :key="3"></div>

          </div>
          <div v-else class="chart"
            style="width: 400px; height: 100%; display: flex; justify-content: center; align-items: center; color: #fff;"
            :key="4">
            暂无数据
          </div>
        </div>
      </div>
    </div>

    <!-- 底部数据 -->
    <div class="footer">
      <div v-if="footerRes" class="footer-item" v-for="(item,index) in footerData" :key="index"
        @click="openLineVisible(index)">
        <div class="footer-img-container">
          <img class="footer-img" :src="item.img" />
          <!-- <span class="footer-num">{{item.unit?item.value:getTimeVal(item.value).value}}</span> -->
          <span>
            <span v-if="index==0" class="footer-num" :class="`footer-num${index}`">
              <animate-number :from="item.unit?oldFooterRes.dtzs||0:getTimeVal(oldFooterRes.dtzs||0).value"
                :to="item.unit?footerRes.dtzs:getTimeVal(footerRes.dtzs).value"
                :key="item.unit?footerRes.dtzs:getTimeVal(footerRes.dtzs).value" duration="1000"></animate-number>
            </span>
            <span v-if="index==1" class="footer-num" :class="`footer-num${index}`">
              <animate-number :from="item.unit?oldFooterRes.lgzyx||0:getTimeVal(oldFooterRes.lgzyx||0).value"
                :to="item.unit?footerRes.lgzyx:getTimeVal(footerRes.lgzyx).value"
                :key="item.unit?footerRes.lgzyx:getTimeVal(footerRes.lgzyx).value" duration="1000"></animate-number>
            </span>
            <span v-if="index==2" class="footer-num" :class="`footer-num${index}`">
              <animate-number :from="item.unit?oldFooterRes.pjxy||0:getTimeVal(oldFooterRes.pjxy||0).value"
                :to="item.unit?footerRes.pjxy:getTimeVal(footerRes.pjxy).value"
                :key="item.unit?footerRes.pjxy:getTimeVal(footerRes.pjxy).value" duration="1000"></animate-number>
            </span>
            <span v-if="index==3" class="footer-num" :class="`footer-num${index}`">
              <animate-number :from="item.unit?oldFooterRes.pjjy||0:getTimeVal(oldFooterRes.pjjy||0).value"
                :to="item.unit?footerRes.pjjy:getTimeVal(footerRes.pjjy).value"
                :key="item.unit?footerRes.pjjy:getTimeVal(footerRes.pjjy).value" duration="1000"></animate-number>
            </span>
            <span v-if="index==4" class="footer-num" :class="`footer-num${index}`">
              <animate-number :from="item.unit?oldFooterRes.ljjy||0:getTimeVal(oldFooterRes.ljjy||0).value"
                :to="item.unit?footerRes.ljjy:getTimeVal(footerRes.ljjy).value"
                :key="item.unit?footerRes.ljjy:getTimeVal(footerRes.ljjy).value" duration="1000"></animate-number>
            </span>
          </span>
        </div>
        <div class="footer-text">{{item.text}}({{item.unit ? item.unit : getTimeVal(item.value).unit}})</div>
      </div>
    </div>

    <!-- 页面加载动画 -->
    <transition name="fade" appear leave-active-class="animate__animated animate__zoomOut">
      <img v-if="isLodin" class="lodinBack" src="@/assets/lodin.gif" />
    </transition>

    <!-- 标注弹框 -->
    <MonitorInfo class="monitor-info" ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo='eleInfo'
      :elevatorId="elevatorId" :deviceCode="deviceCode" @closeMonitor="closeMonitor" />


    <!-- 电梯检测记录弹框 -->
    <elevator-test v-if="elevatorTestVisible" :start="statisticsDate.start" :end="statisticsDate.end"
      :dialogTitle="elevatorTestTitle" @closeDialog="elevatorTestClose" />
    <!-- 故障记录弹框 -->
    <elevator-fault v-if="faultVisible"
      :start="faultType==2?statisticsDate.start || 0 : (faultType==3 ? eventDate.start||0 : (faultType==4 ? analysisDate.start||0 : startMaintainEleDown))"
      :end="faultType==2?statisticsDate.end||0:faultType==3?eventDate.end||0:faultType==4?analysisDate.end||0:endMaintainEleDown"
      :dialogTitle="faultTitle" :status="faultStatus" :openType="faultType" @closeDialog="faultClose" />
    <!-- 电梯维保记录弹框 -->
    <elevator-maintenance v-if="elevatorMaintenanceVisible" :start="statisticsDate.start" :end="statisticsDate.end"
      :dialogTitle="elevatorMaintenanceTitle" :newInterface="false" :status="maintenanceStatus"
      @closeDialog="elevatorMaintenanceClose" />
    <!-- 救援弹框 -->
    <Rescue v-if="rescueVisible" :dialogTitle="rescueTitle"
      :start="rescueType==2 ? statisticsDate.start || 0 : (rescueType==3 ? eventDate.start||0:startMaintainEleDown)"
      :openType="rescueType"
      :end="rescueType==2?statisticsDate.end||0:rescueType==3?eventDate.end||0:endMaintainEleDown"
      :initStatus="rescueStatus" @closeDialog="rescueClose" />
    <!-- 告警弹框 -->
    <elevator-warning v-if="warningVisible" :sosTopType="warningType == 2 ? sosTopType : ''"
      :start="warningType==1?eventDate.start:analysisDate.start" :end="warningType==1?eventDate.end:analysisDate.end"
      :openType="warningType" :dialogTitle="warningTitle" @closeDialog="warningClose" />
    <!-- 救援详情弹框 -->
    <rescue-detail v-if="rescueDetailVisible" :initDioDetailsItem="rescueDioDetailsItem"
      :detailsItem="rescueDetailsItem" :stateList="statusList" @closeDialog="rescueDetailVisible=false" />
    <!-- 告警详情弹框 -->
    <elevator-warning-detail v-if="warningDetailVisible" :initDioDetailsItem="warningDioDetailsItem"
      :detailsItem="warningDetailsItem" @closeDialog="warningDetailVisible=false" />
    <!-- 电梯数据弹框 -->
    <elevator-line v-if="lineVisible" :initLineStatus="initLineStatus" :initFaultStatus="initFaultStatus"
      @closeDialog="lineVisible=false" />
    <!-- 风险统计弹框 -->
    <Risk v-if="riskVisible" :riskType="riskType" @closeDialog="riskVisible=false" />

    <!-- 警报弹窗 -->
    <sos-dialog></sos-dialog>
  </div>
</template>

<script>
  import 'animate.css';
  import {
    formatDate,
    secondToHMS,
    getTimeVal,
    getDay
  } from './untils';
  import bin1 from './com/bin.vue';
  import dateBtns from './com/date-btns.vue';
  import Rescue from './dialog/rescue.vue';
  import ElevatorFault from './dialog/elevator-fault.vue';
  import ElevatorTest from './dialog/elevator-test.vue';
  import ElevatorMaintenance from './dialog/elevator-maintenance.vue';
  import ElevatorWarning from './dialog/elevator-warning.vue';
  import RescueDetail from './dialog/rescue-detail.vue'
  import ElevatorWarningDetail from './dialog/elevator-warning-detail.vue'
  import ElevatorLine from './dialog/elevator-line.vue';
  import Risk from './dialog/risk.vue';
  import SosDialog from './com/sos-dialog.vue'
  import MonitorInfo from '../dtsj/dtjk/components/monitorInfo.vue'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import * as echarts from 'echarts';
  let myChart = null
  let mapGD = null
  let markers = []
  let isOne = true
  export default {
    data() {
      return {
        // 加载动画
        isLodin: true,
        //echarts相关数据
        //地图json数据
        // butObj: JSON.parse(sessionStorage.getItem("adminInfo")).buttons,
        isShowDialog: false,
        echartsJson: {
          features: [],
          type: "FeatureCollection"
        },
        //地图标注设备数据
        convertData: [],
        //当前地图区域信息对象
        regionData: {},
        initRegionData: {},
        //地图查询历史
        echartsHistory: [],
        //地图标注渐变区间值
        mapList: [], //跳过区县级直接跳转高德
        colorIntervalValue: [{}],

        //高德地图相关
        //地图开关
        showMapGD: false,
        //地图中心点
        mapCenterGD: null,
        // 标注弹框数据
        eleInfo: null, //总数据
        elevatorId: '', // 电梯id
        deviceCode: '', // 设备号
        //标注弹框开关
        MonitorInfoVisible: false,
        //ws定时器对象
        facilityTimer: null,

        //默认统计区间:1周、2月、3年
        dateVal: 2,
        startMaintainEleDown: 0,
        endMaintainEleDown: 0,

        //图表标题
        leftTitleA: '风险等级',
        leftTitleB: '运营统计',
        leftTitleC: '事件统计',
        rightTitleA: '实时救援',
        rightTitleB: '实时报警',
        rightTitleC: '报警类型TOP5',

        //风险等级
        leftEchartsA: null,
        //运行统计
        leftEchartsBShow: 1,
        leftEchartsB: null,
        statisticsDate: null,
        statisticsDateVal: this.dateVal,
        statisticsData: {},
        statisticsWeek: [],
        statisticsMonth: [],
        statisticsYear: [],
        statisticsInitStatus: true,


        //事件统计
        eventDate: null,
        eventDateVal: this.dateVal,
        leftEchartsC: null,
        leftEchartsCShow: 1,
        eventNum: {},
        eventWeek: {},
        eventMonth: {},
        eventYear: {},
        eventInitStatus: true,

        //故障分析
        rightEchartsCShowData: true,
        rightEchartsCShow: 1,
        rightEchartsC: null,
        analysisDate: null,
        analysisDateVal: this.dateVal,
        analysisData: {},
        analysisWeek: [],
        analysisMonth: [],
        analysisYear: [],
        analysisInitStatus: true,


        //实施救援滚动列表配置
        rescueClassOption: {
          step: 0.5,
          limitMoveNum: 4,
          autoPlay: true,
        },
        //实时救援列表
        rescueList: [],
        rescueActiveIndex: -1,
        //实时救援状态
        rescueStatusObj: {
          1: {
            value: '待处理',
            class: 'rescue-status-1',
            img: require("@/assets/newBigmianban/surface-1.png"),
          },
          2: {
            value: '救援中',
            class: 'rescue-status-2',
            img: require("@/assets/newBigmianban/surface-1.png"),
          },
          3: {
            value: '救援中',
            class: 'rescue-status-2',
            img: require("@/assets/newBigmianban/surface-1.png"),
          },
          4: {
            value: '救援完成',
            class: 'rescue-status-3',
            img: require("@/assets/newBigmianban/surface-2.png"),
          }
        },

        //实施故障滚动列表配置
        faultClassOption: {
          step: 0.5,
          limitMoveNum: 4,
          autoPlay: true,
        },
        //实时故障列表
        faultList: [],
        faultActiveIndex: -1,
        //实时故障状态
        faultStatusObj: {
          4: {
            value: '急停',
            class: 'fault-status-4',
            img: require("@/assets/newPanel/fault.png"),
          },
          5: {
            value: '困人',
            class: 'fault-status-5',
            img: require("@/assets/newPanel/fault.png"),
          },
          7: {
            value: '电梯停电',
            class: 'fault-status-7',
            img: require("@/assets/newPanel/fault.png"),
          },
          8: {
            value: '关门异常',
            class: 'fault-status-8',
            img: require("@/assets/newPanel/fault.png"),
          },
          9: {
            value: '超速',
            class: 'fault-status-9',
            img: require("@/assets/newPanel/fault.png"),
          },
          10: {
            value: '非平层停梯',
            class: 'fault-status-10',
            img: require("@/assets/newPanel/fault.png"),
          },
          11: {
            value: '冲顶',
            class: 'fault-status-11',
            img: require("@/assets/newPanel/fault.png"),
          },
          12: {
            value: '蹲底',
            class: 'fault-status-12',
            img: require("@/assets/newPanel/fault.png"),
          },
          13: {
            value: '电动车入梯',
            class: 'fault-status-13',
            img: require("@/assets/newPanel/fault.png"),
          },
          95: {
            value: '开门行梯',
            class: 'fault-status-95',
            img: require("@/assets/newPanel/fault.png"),
          },
        },
        //底部数据图片
        footerData: [{
            value: 0,
            text: '总电梯数',
            img: require("@/assets/newPanel/footerBg.png"),
            unit: '台'
          },
          {
            value: 0,
            text: '零故障运行',
            img: require("@/assets/newPanel/footerBg.png"),
            unit: ''
          },
          {
            value: 0,
            text: '平均响应',
            img: require("@/assets/newPanel/footerBg.png"),
            unit: ''
          },
          {
            value: 0,
            text: '平均救援',
            img: require("@/assets/newPanel/footerBg.png"),
            unit: ''
          },
          {
            value: 0,
            text: '累计救援',
            img: require("@/assets/newPanel/footerBg.png"),
            unit: '次'
          },
        ],
        footerRes: null,
        oldFooterRes: {},
        //时间定时器
        timeVal: [],
        day: '',
        timer: null,

        //全屏开关
        fullscreen: false,

        //救援开关
        rescueVisible: false,
        rescueTitle: '',
        rescueType: 1,
        rescueStatus: '',

        //故障列表开关
        faultVisible: false,
        faultType: 1,
        faultTitle: '',
        faultStatus: '',

        //电梯检测记录开关
        elevatorTestVisible: false,
        elevatorTestTitle: '',
        elevatorTestType: '',

        //电梯维保
        elevatorMaintenanceVisible: false,
        elevatorMaintenanceTitle: '',
        maintenanceStatus: '',

        //告警弹窗开关
        warningType: 1,
        sosTopType: '',
        warningVisible: false,
        warningTitle: '',

        //救援详情弹框
        rescueDetailVisible: false,
        rescueDioDetailsItem: {},
        rescueDetailsItem: {
          seleId: 1,
          seleArr: [{
            value: 1,
            label: '报警消息'
          }, {
            value: 2,
            label: '运行参数'
          }, {
            value: 3,
            label: '处置流程'
          }]
        },
        statusList: [],
        typeList: [],

        //告警详情弹框
        warningDetailVisible: false,
        warningDetailsItem: {
          seleId: 1,
          seleArr: [{
            value: 1,
            label: '报警消息'
          }, {
            value: 2,
            label: '运行参数'
          }, {
            value: 3,
            label: '处置流程'
          }]
        },
        warningDioDetailsItem: null,
        sosStatusOptions: [{
            value: 1,
            label: '待处理'
          },
          {
            value: 2,
            label: '已处理'
          },
          {
            value: 3,
            label: '进行中'
          },
          {
            value: 4,
            label: '已完成'
          },
        ],

        //电梯数据弹窗开关
        lineVisible: false,
        initLineStatus: 1,
        initFaultStatus: 0,

        //风险统计弹框
        riskVisible: false,
        riskType: null,

        leftEchartsBHasClick: false,
        rightEchartsCHasClick: false,
        leftEchartsCHasClick: false,

        timerId: null,
        angle: 0,

        timeOutEr: null,

        platformName: JSON.parse(sessionStorage.getItem('adminInfo')).platformName,
        logoUrl: JSON.parse(sessionStorage.getItem('adminInfo')).logoUrl,
        windowHost: window.location.hostname != 'zxtlw.zyzntech.com',
        butObj: JSON.parse(sessionStorage.getItem("adminInfo")).buttons,
        isShowDialog: false,

        nowEchartMap: undefined

      }
    },
    computed: {


    },
    watch: {
      footerRes(value, oldValue) {
        if (oldValue) {
          this.oldFooterRes = oldValue
        }
      },
    },
    components: {
      bin1,
      MonitorInfo,
      vueSeamlessScroll,
      dateBtns,
      ElevatorFault,
      Rescue,
      ElevatorTest,
      ElevatorMaintenance,
      ElevatorWarning,
      RescueDetail,
      ElevatorWarningDetail,
      ElevatorLine,
      Risk,
      SosDialog

    },
    methods: {
      //绘制echarts地图
      // async echartMapInit(regionData={name:'中国',adcode:0},resetMap=true,historyData){
      async echartMapInit(regionData = this.initRegionData, resetMap = true, historyData) {
        // myChart.showLoading({ text: '正在加载数据' });
        if (historyData) {
          this.regionData = historyData.regionData
          this.echartsJson = historyData.echartsJson
          this.convertData = historyData.convertData
        } else {
          this.regionData = regionData
          const res = await this.$http.get(`/api/ele/web/bigScreen/map/elevator?adcode=${this.regionData.adcode}`)
          if (res.status == 200 && res.data.code == 200) {
            this.echartsJson = res.data.data.aliData
            this.getConvertData(res.data.data.countData.filter(item => item.count > 0))
          }
        }
        //调用我们通过json对象注册的地图
        echarts.registerMap('China', this.echartsJson);
        //记录历史
        this.recordHistory({
          regionData: this.regionData,
          echartsJson: this.echartsJson,
          convertData: this.convertData
        })
        //设置地图选项
        this.setMapOption(resetMap, this.regionData.adcode)
        //文件加载的动画
        // myChart.hideLoading();
      },
      //绑定echarts点击事件
      bindClick() {
        myChart.on('click', (e) => {
          const regionData = this.getRegionData(e.name)
          if (regionData.level == "district" || (regionData.level == "city" && regionData.childrenNum == 0) ||
            regionData.name == "香港特别行政区" || regionData.name == "澳门特别行政区" || regionData.name == "台湾省" || this.mapList
            .find(item => item.label == regionData.name)) {
            this.showMapGD = true
            this.mapCenterGD = regionData.center
            this.mapInitGD()
          } else {
            this.echartMapInit(regionData)
            this.nowEchartMap = regionData
          }
        })
      },
      //获取分散点设备数量
      getConvertData(countData) {
        this.convertData = countData.map(item => {
          let data = this.echartsJson.features.filter(echartsItem => echartsItem.properties.adcode == item.adcode)[
            0]
          const value = [...data.properties.center, item.count]
          return {
            name: data.properties.name,
            value
          }
        })
        return this.convertData
      },
      //根据地区名称获取相关信息
      getRegionData(name) {
        const res = this.echartsJson.features.filter(item => item.properties.name == name)
        return res.length > 0 ? res[0].properties : {}
      },
      //记录地图查询历史
      recordHistory(data) {
        const level = (data.regionData.adcode == 100000 || data.regionData.adcode == 0) ? 0 : data.regionData.level ==
          "province" ? 1 : data.regionData.level == "city" ? 2 : 3
        this.echartsHistory.splice(level, this.echartsHistory.length - level, {
          ...data,
          level
        })
      },
      //更新地图区域
      goRegion(item) {
        this.nowEchartMap = item.regionData
        this.echartMapInit(undefined, true, item)
      },
      //设置事件
      setGeoroam() {
        myChart.on("georoam", (params) => {
          var option = myChart.getOption(); //获得option对象
          if (params.zoom != null && params.zoom != undefined) {
            //捕捉到缩放时
            option.geo[0].zoom = option.series[0].zoom; //下层geo的缩放等级跟着上层的geo一起改变
            option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
          } else {
            //捕捉到拖曳时
            option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
          }
          myChart.setOption(option, {
            lazyUpdate: true
          }); //设置option
        });
      },
      mouseleave() {
        this.echartMapInit(this.nowEchartMap)
      },
      //配置echarts选项
      setMapOption(resetMap = true, adcode) {
        let imgSrc = require("../../assets/newBigmianban/map-icon.png");
        // let imgSrc=require("../../assets/full.jpg");
        imgSrc = `image://${imgSrc}`
        myChart = myChart ? myChart : echarts.init(document.getElementById('myChart'));
        //地图开始
        const option = {
          //设置背景颜色
          // backgroundColor: '#020933',
          geo: {
            map: 'China', //地图为刚刚设置的China
            aspectScale: 0.8, //长宽比
            // zoom:1,//当前视角的缩放比例
            roam: true, //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            animationDurationUpdate: 0,
            top: adcode == 0 ? 170 : 30,
            //地图上文字
            label: {
              normal: {
                show: true, //是否显示标签
                textStyle: {
                  color: '#fff',
                  fontSize: 12,
                },
                formatter: (p) => {
                  switch (p.name) {
                    case "内蒙古自治区":
                      p.name = "内蒙古";
                      break;
                    case "西藏自治区":
                      p.name = "西藏";
                      break;
                    case "新疆维吾尔自治区":
                      p.name = "新疆";
                      break;
                    case "宁夏回族自治区":
                      p.name = "宁夏";
                      break;
                    case "广西壮族自治区":
                      p.name = "广西";
                      break;
                    case "香港特别行政区":
                      p.name = "";
                      break;
                    case "澳门特别行政区":
                      p.name = "";
                      break;
                    default:
                      break;
                  }
                  return p.name;
                }
              },
              emphasis: {
                textStyle: {
                  color: '#fff',
                },
              },
            },
            itemStyle: { //地图区域的多边形 图形样式
              // normal: {
              // 	borderColor: 'rgba(147, 235, 248, 1)',
              // 	areaColor: '#2579c3',//地区颜色
              // 	shadowColor: '#182f68',//阴影颜色
              // 	shadowOffsetX: 0,//阴影偏移量
              // 	shadowOffsetY: 0,//阴影偏移量
              // },
              normal: {
                borderColor: 'rgba(147, 235, 248, 1)',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [{
                    offset: 0,
                    color: '#004196' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#397ace' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: 'rgba(128, 217, 248, 1)',
                // shadowColor: 'rgba(255, 255, 255, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10
              },
              emphasis: {
                areaColor: '#04459a', //地区颜色
                label: {
                  show: false, //是否在高亮状态下显示标签
                },
                borderWidth: 1.0,
                shadowBlur: 25,
              },
            },
          },
          series: [ //数据系列
            // {
            // 	type: 'map',//地图类型
            // 	zoom: 1.1,//当前视角的缩放比例
            // 	//是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            // 	roam: true,
            // 	animationDurationUpdate:0,
            // 	map: 'China', //使用中国地图
            // 	//地图上文字
            // 	label: {
            // 		normal: {
            // 			show: true,//是否显示标签
            // 			textStyle: {
            // 				color: '#fff',
            // 			},
            // 		},
            // 		emphasis: {
            // 			textStyle: {
            // 				color: '#fff',
            // 			},
            // 		},
            // 	},
            // 	//地图区域的多边形 图形样式
            // 	itemStyle: {
            // 		normal: {
            // 			borderColor: '#2ab8ff',
            // 			borderWidth: 1.5,
            // 			areaColor: '#12235c',
            // 		},
            // 		emphasis: {
            // 			areaColor: '#2AB8FF',
            // 			borderWidth: 0,
            // 		},
            // 	},
            // },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              geoIndex: 0,
              symbol: 'circle',
              symbolSize: 6,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                scale: 10
              },
              hoverAnimation: true,
              label: {
                show: false,
                formatter: name => {
                  return name.data[2];
                },
                position: 'right',
                color: '#fff',
                fontSize: 14,
                distance: 10
              },
              itemStyle: {
                color: 'rgba(0, 255, 246, 1)',
              },
              zlevel: 1,
              data: this.convertData,
            }, {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              geoIndex: 0,
              silent: true,
              symbol: 'circle',
              symbolSize: 4,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
                scale: 6
              },
              hoverAnimation: true,
              label: {
                formatter: '',
                position: 'right',
                color: '#fff',
                fontSize: 14,
                distance: 10,
                show: !0,
              },
              itemStyle: {
                color: 'rgba(255, 255, 255, 0.8)',
              },
              zlevel: 1,
              data: this.convertData,
            },
            {
              //设置为分散点
              type: 'scatter',
              zoom: 1.2, //当前视角的缩放比例
              //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
              roam: true,
              animationDurationUpdate: 0,
              //series坐标系类型
              coordinateSystem: 'geo',
              //设置图形 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
              symbol: imgSrc,
              symbolOffset: [0, '-40%'],
              // //标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10
              // symbolSize: [30,33],
              symbolSize: function(value) {
                if (value[2] > 999) {
                  return [50, 53];
                }
                return [30, 33];
              },
              //气泡字体设置
              label: {
                normal: {
                  show: true, //是否显示
                  textStyle: {
                    fontWeight: 'bold',
                    color: '#0063e9', //字体颜色
                    fontSize: 13, //字体大小
                  },
                  //返回气泡数据
                  formatter(value) {
                    // return value.data.value[2] > 999 ? '999+' : value.data.value[2]
                    return value.data.value[2]
                  }
                },
                emphasis: { //动态展示的样式
                  // color:'#fff',
                  // fontSize: 15,//字体大小
                  //返回气泡数据
                  formatter(value) {
                    return value.data.value[2]
                  }
                },
              },
              itemStyle: {
                normal: {
                  color: '#1518E5', //标志颜色
                  borderColor: '#fff'
                }
              },
              //给区域赋值
              data: this.convertData,
              showEffectOn: 'render', //配置何时显示特效。可选：'render' 绘制完成后显示特效。'emphasis' 高亮（hover）的时候显示特效。
              rippleEffect: { //涟漪特效相关配置。
                brushType: 'fill' //波纹的绘制方式，可选 'stroke' 和 'fill'
              },
              hoverAnimation: true, //是否开启鼠标 hover 的提示动画效果。
              zlevel: 2 //所属图形的 zlevel 值
            },
          ],
        };
        if (resetMap) {
          option.geo.zoom = adcode == 0 ? 1.3 : 1
        }
        myChart.setOption(option, resetMap);
      },
      //初始化高德地图
      mapInitGD() {
        this.$nextTick(() => {
          mapGD = new AMap.Map('mapGD', {
            mapStyle: "amap://styles/darkblue",
            rotateEnable: true,
            pitchEnable: true,
            zoom: 13,
            pitch: 50,
            rotation: -15,
            // viewMode: '3D', //开启3D视图,默认为关闭
            zooms: [2, 20],
            center: this.mapCenterGD
          })
          if (this.timeOutEr) {
            clearTimeout(this.timeOutEr)
          }
          this.timeOutEr = setTimeout(() => {
            // this.getFacility()
            this.setMapMarker(this.liftArr)
            if (this.facilityTimer) {
              clearInterval(this.facilityTimer)
            }
            this.facilityTimer = setInterval(() => {
              // this.getFacility()
              this.setMapMarker(this.liftArr)
            }, 59000)
          }, 1000)
        })
      },
      //返回echart地图
      backEcharts() {
        this.showMapGD = false
      },
      //获取地图设备数据
      getFacility() {
        // if (this.socketApi.isheart) {
        // 	let agentData = {
        // 		act: 'ma_get_ele_distribution',
        // 		token: sessionStorage.getItem('AdminToken')
        // 	}
        // 	this.socketApi.sendSock(agentData, (e) => {
        // 		if (e.cmd == 'ma_get_ele_distribution' && e.status) {
        // 			let data = JSON.parse(e.data)
        // 			this.eleDistri = data.eleDistributionTop
        // 			this.liftArr = data.eleList
        // 			this.setMapMarker(data.eleList)
        // 		}
        // 	})
        // }

        this.$http
          .post(`/api/ele/web/elevatorInfo/getEleDistribution`, {})
          .then((res) => {
            if (res.data.success) {
              this.eleDistri = res.data.data.eleDistributionTop
              this.liftArr = res.data.data.eleList ? res.data.data.eleList : []
              // this.setMapMarker(res.data.data.eleList)
            }
          })
      },
      //关闭信息窗体
      closeInfoWindow() {
        mapGD.clearInfoWindow();
      },
      //获取地图自定义窗体
      getContent(qs, isStatus) {
        var info = document.createElement("div");
        var top = document.createElement("div");
        var closeX = document.createElement("img");
        info.className = `custom-info ${isStatus.class}`;
        info.style.width = "237px";
        closeX.className = "close-x";
        top.className = "info-top";
        closeX.src = require("../../assets/newBigmianban/close.png");
        closeX.onclick = this.closeInfoWindow;
        top.appendChild(closeX);
        info.appendChild(top);
        var middle = document.createElement("div");
        middle.className = "info-content";
        middle.innerHTML = `<div class="info-name">${qs.name}</div>
									<div class="info-code">电梯注册代码： ${qs.code}</div>
									<div class="info-address">安装位置： ${qs.eleAddress}</div>`;
        info.appendChild(middle);
        return info
      },
      //设置标注
      setMapMarker(data = []) {
        // mapGD.remove(markers)
        if (mapGD) {
          mapGD.clearMap()
        }
        // data.forEach((qs) => {
        // 	if (qs.latitude && qs.longitude) {
        // 		let isStatus = this.getStatus(qs.status)
        // 		let icon = new AMap.Icon({
        // 			size: new AMap.Size(22, 31), // 图标尺寸
        // 			image: isStatus.icon, // Icon的图像
        // 			imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        // 			imageSize: new AMap.Size(22, 31) // 根据所设置的大小拉伸或压缩图片
        // 		});

        // 		let marker = new AMap.Marker({
        // 			position: [
        // 				qs.longitude,
        // 				qs.latitude
        // 			], //位置
        // 			icon: icon, //自定义中端帽子的图标
        // 		})
        // 		// 设置标题
        // 		marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
        // 		markers.push(marker)
        // 		mapGD.add(marker) //添加到地图
        // 		let content = '<div style="background-color:' + isStatus.color +
        // 			';padding: 10px;color:white;">' + qs.name + '(' + isStatus.lab + ')' +
        // 			'<br/>AI摄像头ID:' + qs.deviceCode +
        // 			'<br/>监察识别码:' + qs.rescueCode +
        // 			'<br/>电梯注册代码:' + qs.code +
        // 			'<br/>安装位置:' + qs.eleAddress + '</div>'
        // 		let infoWindow = new AMap.InfoWindow({
        // 			isCustom: true,
        // 			//创建信息窗体
        // 			content: this.getContent(qs,isStatus),
        // 			// content,
        // 			offset: new AMap.Pixel(-30, -40),
        // 		})

        // 		var onMarkerOver = function(e) {
        // 			infoWindow.open(mapGD, e.target.getPosition()) //打开信息窗体
        // 		}
        // 		var onMarkerOut = function() {
        // 			infoWindow.close()
        // 		}
        // 		marker.on('mouseover', onMarkerOver) //绑定mouseover事件
        // 		// marker.on('mouseout', onMarkerOut) //绑定mouseout事件
        // 		let that = this
        // 		var onMarkerClick = function(e) {
        // 			that.eleInfo = qs
        // 			that.elevatorId = qs.id
        // 			that.deviceCode = qs.deviceCode
        // 			that.MonitorInfoVisible = true
        // 		}
        // 		marker.on('click', onMarkerClick) //绑定click事件
        // 	}
        // })
        if (this.cluster) {
          this.cluster.setMap(null);
        }
        const points = data.map(item => ({
          lnglat: [item.longitude, item.latitude],
          otherData: item
        }))
        const count = points.length
        var _renderMarker = (context) => {
          let qs = context.data[0].otherData
          //设置图标
          let isStatus = this.getStatus(qs.status)
          context.marker.setIcon(new AMap.Icon({
            size: new AMap.Size(22, 31), // 图标尺寸
            image: isStatus.icon, // Icon的图像
            imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(22, 31) // 根据所设置的大小拉伸或压缩图片
          }))
          // 设置标题
          context.marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
          //设置点击事件
          context.marker.on('click', () => {
            this.eleInfo = qs
            this.elevatorId = qs.id
            this.deviceCode = qs.deviceCode
            this.MonitorInfoVisible = true
          })
          //移入移出窗口
          let infoWindow = new AMap.InfoWindow({
            isCustom: true,
            //创建信息窗体
            content: this.getContent(qs, isStatus),
            offset: new AMap.Pixel(-32, -40),
          })
          context.marker.on('mouseover', (e) => {
            infoWindow.open(mapGD, e.target.getPosition())
          })
          context.marker.on('mouseout', () => {
            infoWindow.close()
          })
        }
        var _renderClusterMarker = function(context) {
          var div = document.createElement('div');
          div.innerHTML = `<div class="map-marker-icon">${context.count}</div>`;
          context.marker.setOffset(new AMap.Pixel(0, 0));
          context.marker.setContent(div)
        };
        this.cluster = new AMap.MarkerCluster(mapGD, points, {
          gridSize: 60,
          renderClusterMarker: _renderClusterMarker,
          renderMarker: _renderMarker,
        });
        //设置聚合点点击事件
        this.cluster.on('click', (item) => {
          //此处是通过包含点的数量判断是否是聚合点，不是聚合点就执行上方单个点的点击方式
          if (item.clusterData.length <= 1) {
            return;
          }
          //这里是计算所有聚合点的中心点
          let alllng = 0,
            alllat = 0;
          for (const mo of item.clusterData) {
            alllng += mo.lnglat.lng;
            alllat += mo.lnglat.lat;
          }
          const lat = alllat / item.clusterData.length;
          const lng = alllng / item.clusterData.length;
          //这里是放大地图，此处写死了每次点击放大的级别，可以根据点的数量和当前大小适应放大，体验更佳
          mapGD.setZoomAndCenter(mapGD.getZoom() + 2, [lng, lat]);
        });
        if (isOne) {
          isOne = false
          // mapGD.setFitView()
        }
      },
      //关闭标注弹窗
      closeMonitor() {
        this.MonitorInfoVisible = false
      },
      //获取标注状态信息
      getStatus(status) {
        let obj
        switch (status) {
          case 0:
            obj = {
              class: 'window-status-0',
              lab: '离线',
              color: '#4c4c4c',
              icon: require('@/assets/newBigmianban/lx.png')
            }
            break;
          case 1:
            obj = {
              class: 'window-status-1',
              lab: '在线',
              color: '#72D9FF',
              icon: require('@/assets/newBigmianban/zc.png')
            }
            break;
          case 2:
            obj = {
              class: 'window-status-2',
              lab: '发生了报警',
              color: '#ffa0a0',
              icon: require('@/assets/newBigmianban/bj.png')
            }
            break;
          case 3:
            obj = {
              class: 'window-status-3',
              lab: '故障',
              color: '#ff6666',
              icon: require('@/assets/newBigmianban/gz.png')
            }
            break;
          case 4:
            obj = {
              class: 'window-status-4',
              lab: '即将年检',
              color: '#ffbe66',
              icon: require('@/assets/newBigmianban/nj.png')
            }
            break;

          case 5:
            obj = {
              class: 'window-status-5',
              lab: '即将合同到期',
              color: '#759119',
              icon: require('@/assets/newBigmianban/dq.png')
            }
            break;
          case 6:
            obj = {
              class: 'window-status-0',
              lab: '未入网',
              color: '#D7D7D7',
              icon: require('@/assets/newBigmianban/wrw.png')
            }
            break;
        }
        return obj
      },
      //返回平台
      back() {
        const screenData = JSON.parse(sessionStorage.getItem('adminInfo'))
        let routeUrl = ''
        if (screenData.resource[0].children) {
          routeUrl = screenData.resource[0].children[0].routePath
        } else {
          routeUrl = screenData.resource[0].routePath
        }
        this.$router.push(routeUrl)
        // this.$router.push({
        //   path: '/dashboard'
        // })
        // window.history.go(-1)
      },

      getCirlPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos(angle * Math.PI / 180)
        let y1 = y0 + r * Math.sin(angle * Math.PI / 180)
        return {
          x: x1,
          y: y1
        }
      },
      drawRiskLevel() {
        this.angle += 3
        let angle = this.angle
        let getCirlPoint = this.getCirlPoint
        const total = this.riskData.level_1 + this.riskData.level_2 + this.riskData.level_3 + this.riskData.level_4
        const _total = this.riskData.level_3 + this.riskData.level_4
        let bgColor = '#00204a00';
        let title = '风 险';
        //保留两位小数
        let titleVal = '占 比';
        let echartData = [{
            name: "Ⅰ级风险",
            value: this.riskData.level_1,
            total: this.riskData.level_1 / total,
            color: '#34A6BF',
            roundCap: true,
            itemStyle: {
              normal: {
                borderColor: '#001f4a',
                borderRadius: 60,
                borderWidth: 7,
                color: {
                  // 完成的圆环的颜色
                  colorStops: [{
                      offset: 0,
                      color: "#34A6BF11" // 0% 处的颜色
                    },
                    {
                      offset: 0.25,
                      color: "#34A6BF88" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#34A6BFff" // 100% 处的颜色
                    }
                  ]
                },
              }
            },
            labelLine: { //设置延长线的长度
              length: 10, //设置延长线的长度
            },
            label: {
                normal: {
                  formatter: params => {
                    return (
                      '{name|' + params.name + '}\n{value|' +
                      formatPercentage(params.value) + '}'
                    );
                  },
                  // padding: [0 , -100, 25, -100],
                  rich: {
                    icon: {
                      fontSize: 16,
                      color: '#0F8200'
                    },
                    name: {
                      fontSize: 14,
                      padding: [-20, 10, 0, -10],
                      color: '#fff'
                    },
                    value: {
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: [-30, 25, 0, -5],
                      lineHeight: 20,
                      color: '#00FFFD'
                    }
                  }
                }
              },
            
          },
          {
            name: "Ⅱ级风险",
            value: this.riskData.level_2,
            total: this.riskData.level_2 / total,
            color: '#83F9F8',
            roundCap: true,
            itemStyle: {
              normal: {
                borderColor: '#001f4a',
                borderRadius: 60,
                borderWidth: 7,
                color: {
                  // 完成的圆环的颜色
                  colorStops: [{
                      offset: 0,
                      color: "#83F9F811" // 0% 处的颜色
                    },
                    {
                      offset: 0.25,
                      color: "#83F9F888" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#83F9F8ff" // 100% 处的颜色
                    }
                  ]
                },
              }
            },
            labelLine: { //设置延长线的长度
              length: 10, //设置延长线的长度
            },
          },
          {
            name: "Ⅲ级风险",
            value: this.riskData.level_3,
            total: this.riskData.level_3 / total,
            color: '#4474DD',
            roundCap: true,
            itemStyle: {
              normal: {
                borderColor: '#001f4a',
                borderRadius: 60,
                borderWidth: 7,
                color: {
                  // 完成的圆环的颜色
                  colorStops: [{
                      offset: 0,
                      color: "#4474DD11" // 0% 处的颜色
                    },
                    {
                      offset: 0.25,
                      color: "#4474DD88" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#4474DDff" // 100% 处的颜色
                    }
                  ]
                },
                label: {
                  show: false
                }
              }
            },
            labelLine: { //设置延长线的长度
              length: 20, //设置延长线的长度
            },
            label: {
                normal: {
                  formatter: params => {
                    return (
                      '{name|' + params.name + '}\n{value|' +
                      formatPercentage(params.value) + '}'
                    );
                  },
                  // padding: [0 , -100, 25, -100],
                  rich: {
                    icon: {
                      fontSize: 16,
                      color: '#0F8200'
                    },
                    name: {
                      fontSize: 14,
                      padding: [0, 10, 0, 25],
                      color: '#fff'
                    },
                    value: {
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: [0, 25, 0, 25],
                      lineHeight: 20,
                      color: '#00FFFD'
                    }
                  }
                }
              },
          },
          {
            name: "Ⅳ级风险",
            value: this.riskData.level_4,
            total: this.riskData.level_4 / total,
            color: '#3CAFFF',
            roundCap: true,
            itemStyle: {
              normal: {
                borderColor: '#001f4a',
                borderRadius: 60,
                borderWidth: 7,
                color: {
                  // 完成的圆环的颜色
                  colorStops: [{
                      offset: 0,
                      color: "#3CAFFF11" // 0% 处的颜色
                    },
                    {
                      offset: 0.25,
                      color: "#3CAFFF88" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#3CAFFFff" // 100% 处的颜色
                    }
                  ]
                },
                label: {
                  show: false
                }
              }
            },
            labelLine: { //设置延长线的长度
              length: 20, //设置延长线的长度
            },
          }
        ];
        // echartData = echartData.filter(item => item.value > 0)

        let formatPercentage = function(num) {
          return num / total * 100 >= 1 ? `${((num/total*100).toFixed())}%` : (num != 0 ? '< 1%' : '0%');
        }
        const option = {
          avoidLabelOverlap: false,
          color: echartData.map(item => item.color),
          title: [{
            text: '{val|' + title + '}\n\n{name|' + titleVal + '}',
            top: '35%',
            left: 'center',
            textStyle: {
              rich: {
                val: {
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#fff'
                },
                name: {
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#fff',
                }

              }
            }
          }],
          series: [{
              type: 'pie',
              radius: ['47%', '63%'],
              center: ['50%', '50%'],
              data: echartData,
              hoverAnimation: false,
              avoidLabelOverlap: true,
              label: {
                normal: {
                  formatter: params => {
                    return (
                      '{name|' + params.name + '}\n{value|' +
                      formatPercentage(params.value) + '}'
                    );
                  },
                  // padding: [0 , -100, 25, -100],
                  rich: {
                    icon: {
                      fontSize: 16,
                      color: '#0F8200'
                    },
                    name: {
                      fontSize: 14,
                      padding: [-10, 10, 0, 15],
                      color: '#fff'
                    },
                    value: {
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: [-20, 25, 0, 10],
                      lineHeight: 20,
                      color: '#00FFFD'
                    }
                  }
                }
              },
            },
            {
              type: 'pie',
              radius: ['33%', '53%'], // 内半径和外半径
              center: ['50%', '50%'],
              hoverAnimation: false,
              avoidLabelOverlap: true,
              label: false,
              data: echartData,
              itemStyle: {
                borderRadius: 25, // 设置圆角的半径大小
              },
            }
          ]
        };
        this.leftEchartsA.setOption(option);
      },
      //设置风险等级
      async riskLevelInit() {
        this.leftEchartsA = this.leftEchartsA ? this.leftEchartsA : echarts.init(document.getElementById(
          'left-box-a'));
        const res = await this.$http.get(`/api/ele/web/bigScreen/jg/fxdj`)
        if (res.status == 200 && res.data.code == 200) {
          this.riskData = res.data.data
        }
        this.drawRiskLevel()
        if (this.timerId) {
          clearInterval(this.timerId)
        }
        this.timerId = setInterval(() => {
          this.drawRiskLevel()
        }, 100);
      },

      //设置运行统计

      getfnStatisticsInit() {
        this.fnStatisticsInit(1, this.getWeek())
      },

      async fnStatisticsInit(type, date) {
        const {
          start,
          end
        } = date
        const res = await this.$http.get(
          `/api/ele/web/bigScreen/jg/yxtj?start=${formatDate(start)}&end=${formatDate(end)}`)
        if (res.status == 200 && res.data.code == 200) {
          if (type == 1) {
            this.statisticsWeek = [res.data.data.nianjian, res.data.data.weixiu, res.data.data.weibao, res.data.data
              .jiuyuan
            ]
            this.getNewFnStatisticsInit(1)
            this.$nextTick(() => {
                this.fnStatisticsInit(2, this.getMonth())
                this.fnStatisticsInit(3, this.getYear())
            })
          }
          if (type == 2) {
            this.statisticsMonth = [res.data.data.nianjian, res.data.data.weixiu, res.data.data.weibao, res.data.data
              .jiuyuan
            ]
          }
          if (type == 3) {
            this.statisticsYear = [res.data.data.nianjian, res.data.data.weixiu, res.data.data.weibao, res.data.data
              .jiuyuan
            ]
          }
        }
      },
      changefnStatistics(val) {
        this.getNewFnStatisticsInit(val)
        this.leftEchartsBShow = val
      },

      getNewFnStatisticsInit(val) {
        let yList = val == 1 ? this.statisticsWeek : (val == 2 ? this.statisticsMonth : this.statisticsYear)
        this.leftEchartsB = echarts.init(document.getElementById(`left-box-b${val}`))
        let baryList = []
        yList.map(item => {
          baryList.push(item == 0 ? 0 : (item - 2 > 0 ? item - (item * 0.1 > 1 ? 1 : item * 0.1) : 1))
        })
        let xData = ['年检', '维修', '维保', '救援'];
        let colorArr = [
          ['#7cf5f8', '#7cf5f8', '#4ccfd1', '#4ccfd1'],
          ['#FFBA00', '#FFD461', '#D69C00', '#D69C00'],
          ['#115BE3', '#115BE3', '#0445BC', '#0445BC'],
          ['#E36511', '#E36511', '#AE4803', '#AE4803'],
        ];
        let dom = 500;
        let barWidth = dom / 20;
        let colors = []
        for (let i = 0; i < 4; i++) {
          colors.push({
            type: 'linear',
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
            colorStops: [{
              offset: 0,
              color: colorArr[i][0] // 最左边
            }, {
              offset: 0.5,
              color: colorArr[i][1] // 左边的右边 颜色
            }, {
              offset: 0.5,
              color: colorArr[i][2] // 右边的左边 颜色
            }, {
              offset: 1,
              color: colorArr[i][3]
            }]
          })
        }
        const option = {
          grid: {
            left: '5%',
            right: '5%',
            top: '15%',
            bottom: '5%',
            containLabel: true
          },
          tooltip: {
            trigger: 'item',
            formatter: function(parms) {
              return parms.marker + " " + parms.name + "：" + parms.value;
            }
          },
          xAxis: {
            type: 'category', // category(坐标轴类型)
            data: xData,
            axisTick: { // 坐标轴刻度相关配置
              show: false // 是否显示坐标轴刻度
            },
            axisLine: { // 坐标轴轴线相关配置
              lineStyle: { // 坐标轴轴线样式
                color: 'rgba(255,255,255,0.15)' // 坐标轴轴线颜色
              }
            },
            axisLabel: { // 坐标轴刻度标签相关配置
              color: '#ffffff',
              fontSize: 14,
              margin: 20
            }
          },
          yAxis: {
            type: 'value', // value(数值轴,适用于连续数据)
            minInterval: 1,
            axisTick: { // 坐标轴刻度相关配置
              show: false // 是否显示坐标轴刻度
            },
            axisLine: { // 坐标轴轴线相关配置
              show: false // 是否显示坐标轴轴线
            },
            axisLabel: { // 坐标轴刻度标签相关配置
              color: '#ffffff99',
              fontSize: 14
            },
            splitLine: { // 坐标轴在 grid 区域中的分隔线
              lineStyle: { // 分割线配置
                color: 'rgba(255,255,255,0.15)' // 分割线颜色
              }
            }
          },
          series: [
            // 底部的椭圆形(象形柱图):pictorialBar
            {
              type: "pictorialBar", // pictorialBar(象形柱图)
              label: { // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
                show: true, //是否显示标签
                position: ['43%', '-25'], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
                color: '#01E4FF',
                fontSize: 14
              },
              symbolSize: [25, 10], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
              symbolOffset: [0, 5], // 图形相对于原本位置的偏移
              z: 12, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
              itemStyle: { // 图形样式
                // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
                // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  // 这里 offset: 0 1 ,表示从下往上的渐变色
                  {
                    offset: 0, // 0%处的颜色
                    color: "rgba(31,155,255,1)"
                  },
                  {
                    offset: 1, // 100%处的颜色
                    color: "rgba(0,229,255,1)"
                  }
                ])
              },
              data: yList
            },
            // 中间的长方形柱状图(柱状图):bar
            {
              type: 'bar', // 柱状图
              barWidth: 25, // 柱条的宽度,不设时自适应
              barGap: '0%', // 柱子与柱子之间的距离
              itemStyle: { // 图形样式
                // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
                color: '#0001'
              },
              data: yList
            },
            {
              type: 'pictorialBar',
              symbol: 'rect', //图形类型，带圆角的矩形
              symbolRepeat: false, //图形是否重复
              // 分隔
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(0,229,255,0.2)"
                  },
                  {
                    offset: 1,
                    color: "#1F9BFF"
                  }
                ], false)
              },
              symbolClip: true,
              symbolSize: [25, '92%'],
              symbolPosition: 'start',
              data: yList,
              z: 14,
            },

            // 顶部的椭圆形(象形柱图):pictorialBar
            {
              type: "pictorialBar",
              symbolSize: [25, 10],
              symbolOffset: [0, "10%"],
              z: 12,
              symbolPosition: "end",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "rgba(31,155,255,1)"
                  },
                  {
                    offset: 1,
                    color: "rgba(0,229,255,1)"
                  }
                ], false)
              },
              data: yList
            },
            {
              type: 'line',
              smooth: true,
              itemStyle: {
                normal: {
                  color: 'rgba(31,155,255,1)',
                  borderColor: 'rgba(31,155,255,1)', // 线的颜色
                  borderWidth: 2 // 线的宽度
                }
              },
              symbolSize: 8,
              data: yList
            }
          ]
        }
        this.leftEchartsB.setOption(option);
        this.$nextTick(() => {
          this.leftEchartsB.resize()
        })
        this.leftEchartsB.on('click', (param) => {
          if (this.isShowDialog && param.value != '0') {
            this.$refs.leftBoxB.removeTimer()
            let dateText = this.leftEchartsBShow == 1 ? '本周' : this.leftEchartsBShow == 3 ? '本年' : '本月'
            this.statisticsDate = this.leftEchartsBShow == 1 ? this.getWeek() : (this.leftEchartsBShow == 3 ? this
              .getYear() : this.getMonth())
            if (param.name == '年检') {
              this.elevatorTestTitle = `${dateText}已检验`
              this.elevatorTestVisible = true
              this.elevatorTestType = 2
            } else if (param.name == '维修') {
              this.faultTitle = `${dateText}已维修`
              this.faultVisible = true
              this.faultType = 2
              this.faultStatus = 3
            } else if (param.name == '维保') {
              this.elevatorMaintenanceTitle = `${dateText}已维保`
              this.elevatorMaintenanceVisible = true
              this.maintenanceStatus = 7
            } else if (param.name == '救援') {
              this.rescueTitle = `${dateText}已救援`
              this.rescueVisible = true
              this.rescueType = 2
              this.rescueStatus = "4"
            }
          }
        })
      },



      //设置故障分析
      getAnalysisInit() {
        this.setAnalysisInit(1, this.getWeek())
      },
      async setAnalysisInit(type, date) {
        const {
          start,
          end
        } = date
        const data = {
          start: new Date(start).getTime(),
          end: new Date(end).getTime()
        }
        const res = await this.$http.post(
          `/api/ele/web/sos/getDataSosTop`, data)
        if (res.status == 200 && res.data.code == 200) {
          if (type == 1) {
            this.analysisWeek = res.data.data
            this.getNewAnalysisInit(1)
            this.$nextTick(() => {
                this.setAnalysisInit(2, this.getMonth())
                this.setAnalysisInit(3, this.getYear())
            })
          }
          if (type == 2) {
            this.analysisMonth = res.data.data
          }
          if (type == 3) {
            this.analysisYear = res.data.data
          }
        }
      },
      changeAnalysisInit(val) {
        this.getNewAnalysisInit(val)
        this.rightEchartsCShow = val
      },
      getNewAnalysisInit(val) {
        let data = val == 1 ? this.analysisWeek : (val == 2 ? this.analysisMonth : this.analysisYear)
        console.log(data, 'data');
        if (data.length > 0) {
          let rightEchartsC = echarts.init(document.getElementById(`right-box-c${val}`))
          this.rightEchartsCShowData = true
          const man = []
          const indicator = []
          data = data.sort((a, b) => a.num - b.num)
          data.map(item => {
            indicator.push(item.sosType)
            man.push(item.num)
          })
          let maxMan = Math.max(...man) / 6
          const option = {
            tooltip: {
              trigger: 'axis',
              backgroundColor: 'rgba(255,255,255,0.7)', // 设置背景色为半透明的白色
              borderWidth: 0,
              borderColor: '#ccc2',
              width: 70,
              textStyle: {
                color: '#fff' // 文字颜色
              },
              extraCssText: 'background: linear-gradient(to left, rgba(34,68,172,0.35) 0%, #83bff6 100%); width: 70px; padding: 5px 10px', //渐变颜色

              // 自定义提示框内容
              formatter: function(params) {
                // params 是一个数组，数组中包含每个系列的数据信息
                let result = `${params[0].name}<br/>`;
                params.forEach(function(item) {
                  result += `${item.value}<br/>`;
                });
                return result;
              }
            },
            grid: {
              left: '0%',
              right: '4%',
              bottom: '3%',
              top: 0,
              containLabel: true
            },
            xAxis: {
              type: 'value',
              position: "top",
              axisLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#fff8', // 坐标轴颜色
                },
              },
              axisLabel: {
                formatter: function(value) {
                  return value >= 10000 ? `${value / 10000}w` : value; // 只显示标签的前3个字符
                }
              },
              boundaryGap: [0, 0.01],
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#fff3'
                }
              }
            },
            yAxis: {
              type: 'category',
              axisLine: {
                lineStyle: {
                  type: 'dashed',
                  color: '#fff8', // 坐标轴颜色
                },
              },
              axisLabel: {
                textStyle: {
                  color: '#fff8', // 坐标轴颜色
                },
              },
              data: indicator
            },
            series: [{
              name: '2011',
              type: 'bar',
              data: man,
              barWidth: 15,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                    offset: 0,
                    color: '#83bff6'
                  },
                  {
                    offset: 0.5,
                    color: '#4C74E0'
                  },
                  {
                    offset: 1,
                    color: '#2e4a70'
                  }
                ]),
              },
            }]
          }
          rightEchartsC.setOption(option);
          this.$nextTick(() => {
            rightEchartsC.resize()
          })

          rightEchartsC.on('click', (param) => {
            if (this.isShowDialog) {

              this.$refs.rightBoxC.removeTimer()
              let dateText = this.rightEchartsCShow == 1 ? '本周' : this.rightEchartsCShow == 3 ? '本年' : '本月'
              this.analysisDate = this.rightEchartsCShow == 1 ? this.getWeek() : (this.rightEchartsCShow == 3 ? this
                .getYear() : this.getMonth())
              this.sosTopType = data.find(item => item.sosType === param.name).type
              this.warningTitle = `${dateText}告警记录`
              this.warningVisible = true
              this.warningType = 2
            }
          })
        } else {
          this.rightEchartsCShowData = false
        }
      },
      // openRightEchart() {
      //   if (this.isShowDialog) {
      //     this.$refs.rightBoxC.removeTimer()
      //     let dateText = this.rightEchartsCShow == 1 ? '本周' : this.rightEchartsCShow == 3 ? '本年' : '本月'
      //     this.analysisDate = this.rightEchartsCShow == 1 ? this.getWeek() : (this.rightEchartsCShow == 3 ? this.getYear() : this.getMonth())
      //     this.warningTitle = `${dateText}告警记录`
      //     this.warningVisible = true
      //     this.warningType = 2
      //     console.log(param,'param');
      //   }
      // },

      //设置事件统计
      // 事件统计
      getEventStatisticsInit() {
        this.setEventStatisticsInit(1, this.getWeek())
      },

      async setEventStatisticsInit(type, date) {
        const {
          start,
          end
        } = date
        const res = await this.$http.get(
          `/api/ele/web/bigScreen/jg/sjtj?start=${formatDate(start)}&end=${formatDate(end)}`)
        if (res.status == 200 && res.data.code == 200) {
          const {
            gaojinshu,
            guzhangshu,
            kunren
          } = res.data.data
          if (type == 1) {
            this.eventWeek = {
              gaojinshu,
              guzhangshu,
              kunren
            }
            this.getNewEventStatistics(1)
          }
          if (type == 2) {
            this.eventMonth = {
              gaojinshu,
              guzhangshu,
              kunren
            }
          }
          if (type == 3) {
            this.eventYear = {
              gaojinshu,
              guzhangshu,
              kunren
            }
          }
        }
      },

      changeEventStatisticsInit(val) {
        this.getNewEventStatistics(val)
        this.leftEchartsCShow = val
      },
      getNewEventStatistics(val) {
        this.eventNum = val != 1 ? (this.eventNum = val != 2 ? this.eventYear : this.eventMonth) : this.eventWeek
        this.$nextTick(() => {
          if (this.eventInitStatus) {
            this.setEventStatisticsInit(2, this.getMonth())
            this.setEventStatisticsInit(3, this.getYear())
            this.eventInitStatus = false
          }
        })
      },
      leftBoxOpen(name) {
        this.$refs.leftBoxC.removeTimer()
        let dateText = this.leftEchartsCShow == 1 ? '本周' : this.leftEchartsCShow == 3 ? '本年' : '本月'
        this.eventDate = this.leftEchartsCShow == 1 ? this.getWeek() : (this.leftEchartsCShow == 3 ? this.getYear() :
          this.getMonth())
        if (this.isShowDialog) {
          console.log(this.eventDate, 'this.eventDate');
          if (name == '报警数') {
            this.warningTitle = `${dateText}报警记录`
            this.warningVisible = true
            this.warningType = 1
          } else if (name == '故障数') {
            this.faultTitle = `${dateText}故障记录`
            this.faultVisible = true
            this.faultType = 3
          } else if (name == '困人数') {
            this.rescueTitle = `${dateText}困人记录`
            this.rescueVisible = true
            this.rescueType = 3
          }
        }
      },

      //获取实时救援
      async getRescueList() {
        const res = await this.$http.get(`/api/ele/web/bigScreen/jg/ssjy`)
        if (res.status == 200 && res.data.code == 200) {
          if (res.data.data.length > 0) {
            this.rescueList = res.data.data.map(item => ({
              ...item,
              time: secondToHMS(((item.status == 4 ? new Date(item.finishTime).getTime() : new Date().getTime()) -
                new Date(item.createTime).getTime()) / 1000, ':', true)
            }))
          }
        }
      },
      //获取实时故障
      async getFaultList() {
        const res = await this.$http.get(`/api/ele/web/bigScreen/jg/ssgz`)
        if (res.status == 200 && res.data.code == 200) {
          if (res.data.data.length > 0) {
            this.faultList = res.data.data.map(item => {
              item.value = this.typeList.find(iitem => iitem.value == item.sosType).label
              return item
            })
          }
          // this.faultList = arr.filter(item => [4, 5, 7, 8, 9, 10, 11, 12, 13, 95].indexOf(item.sosType) != -1)
        }
      },

      //获取底部数据
      async getMaintainEleDown() {
        const {
          start,
          end
        } = this.getThirty()
        this.startMaintainEleDown = start
        this.endMaintainEleDown = end
        const res = await this.$http.get(
          `/api/ele/web/bigScreen/jg/dbsjl?start=${formatDate(start)}&end=${formatDate(end)}`)
        if (res.status == 200 && res.data.code == 200) {
          const {
            dtzs,
            lgzyx,
            pjxy,
            pjjy,
            ljjy
          } = res.data.data
          this.footerRes = res.data.data
          this.footerData[0].value = dtzs
          this.footerData[1].value = lgzyx
          this.footerData[2].value = pjxy
          this.footerData[3].value = pjjy
          this.footerData[4].value = ljjy
        }
      },


      //秒转天时分
      getTimeVal(s) {
        return getTimeVal(s)
      },

      //获取本周开始结束时间
      getWeek() {
        const time = {
          start: 0,
          end: 0
        }
        let arr = [6, 0, 1, 2, 3, 4, 5]
        time.start = parseInt(((new Date(new Date().setHours(0, 0, 0) - arr[new Date().getDay()] * 24 * 60 * 60 * 1000))
          .getTime()) / 1000) * 1000
        time.end = time.start + 7 * 24 * 60 * 60 * 1000 - 1000
        return {
          ...time
        }
      },
      //获取当月开始结束时间
      getMonth() {
        return {
          start: (new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0))).getTime(),
          end: (new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 59)))
            .getTime()
        }
      },
      //获取当年开始结束时间
      getYear() {
        return {
          start: new Date(new Date().getFullYear(), 0, 1).getTime(),
          end: new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 59))
            .getTime()
        }
      },
      //获取往前三十天开始结束时间
      getThirty() {
        return {
          start: new Date(new Date(new Date().getTime() - 86400000 * 30).setHours(0, 0, 0)).getTime(),
          end: new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 59)).getTime()
        }
      },
      //时间定时器
      timeInit() {
        const date = formatDate(new Date())
        this.timeVal = date.split(' ')
        this.timer = setInterval(() => {
          const date = formatDate(new Date())
          this.timeVal = date.split(' ')
          this.day = getDay()
        }, 1000)
      },
      //全屏方法
      handleFullScreen() {
        // 此处可根据获取节点进行区域全屏事件
        let element = document.documentElement;
        if (this.fullscreen) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            // IE11
            element.msRequestFullscreen();
          }
        }
        this.fullscreen = !this.fullscreen;
      },
      //监听屏幕是否全屏
      fullScreenChangeFn(e) {
        if (window.screen.height - window.document.body.offsetHeight > 5) {
          this.fullscreen = false;
        } else {
          this.fullscreen = true;
        }
      },
      //监听按键
      fullScreenKeydown(e) {
        e = e || window.event
        if (e.keyCode === 122 && !this.fullscreen) {
          e.preventDefault()
          this.handleFullScreen()
        }
      },
      pageInit(resetMap = false) {
        const index = this.echartsHistory.length > 0 ? this.echartsHistory.length - 1 : 0
        const mapData = this.echartsHistory[index]
        const regionData = mapData ? mapData.regionData : undefined
        Promise.all([this.echartMapInit(regionData, resetMap), this.riskLevelInit(), this.getfnStatisticsInit(), this
            .getEventStatisticsInit(),
            this.getRescueList(), this.getFaultList(), this.getAnalysisInit(), this.getMaintainEleDown()
          ])
          .then(res => {
            this.isLodin = false
          })
      },
      //重置地图
      mapRest() {
        if (this.showMapGD) {
          mapGD.setZoomAndCenter(13, this.mapCenterGD)
        } else {
          this.nowEchartMap = undefined
          this.echartMapInit(undefined, true)
        }
      },
      //地图返回
      mapBack() {
        if (this.showMapGD) {
          const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
          let arr = this.toManageAreaCode(adminInfo.manageAreaCode)
          if (arr.length <= 3) {
            this.showMapGD = false
          }
        } else {
          let historyLength = this.echartsHistory.length
          if (historyLength > 1) {
            this.goRegion(this.echartsHistory[historyLength - 2])
          }
        }
      },
      mapZoomAdd() {
        if (this.showMapGD) {
          let zoom = mapGD.getZoom() + 0.2
          mapGD.setZoom(zoom)
        } else {
          let option = myChart.getOption();
          option.geo[0].zoom = option.geo[0].zoom + 0.2 < 3 ? option.geo[0].zoom + 0.2 : 3
          myChart.setOption(option, {
            lazyUpdate: true
          });
        }
      },
      mapZoomReduce() {
        if (this.showMapGD) {
          let zoom = mapGD.getZoom() - 0.2
          mapGD.setZoom(zoom)
        } else {
          let option = myChart.getOption();
          option.geo[0].zoom = option.geo[0].zoom - 0.2 > 0.3 ? option.geo[0].zoom - 0.2 : 0.3
          myChart.setOption(option, {
            lazyUpdate: true
          });
        }
      },

      //打开底部电梯弹框
      openLineVisible(index) {
        if (this.isShowDialog) {
          if (index == 4) {
            this.rescueVisible = true
            this.rescueType = 1
            this.rescueTitle = "30天救援记录"
            this.rescueStatus = '4'
          } else if (index == 1 || index == 2 || index == 3) {
            // this.faultType=1
            // this.faultVisible=true
            // this.faultTitle="30天故障记录"
            // this.faultStatus=''
          } else if (index == 0) {
            this.initLineStatus = -1
            this.lineVisible = true
          }
        }
      },

      //打开救援详情
      openRescueDetail(item) {
        this.$http
          .post("/api/ele/web/rescueTask/getInfo", {
            id: item.id
          })
          .then((res) => {
            if (res.data.success) {
              res.data.data.sosVideo.forEach((qs) => {
                let url = qs.videoUrl.split('?');
                qs.videoUrl = url[0]
                const urlname = url[0].split('/').pop().split('_')
                qs.videoName = url ? urlname.length > 1 ? `${urlname[1]}_${urlname[2]}` : url[0].split('/')
                  .pop() : ''
                // qs.videoName = url[1].split('&')[0].split('=')[1]
                // qs.videoName = url[8]
              })
              res.data.data.sosTypeName = this.getTypeText(item.sosType)
              res.data.data.sosStatusName = this.getStatusText(res.data.data.sosStatus)
              res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
              res.data.data.speedarr = res.data.data.speedRecord.split(',');

              this.rescueDetailsItem.seleId = 1
              this.rescueDioDetailsItem = res.data.data
              this.rescueDetailVisible = true

            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      getStatusText(status) {
        const list = this.statusList.filter(item => item.value == status)
        return list.length > 0 ? list[0].label : status
      },
      getTypeText(status) {
        const list = this.typeList.filter(item => item.value == status)
        return list.length > 0 ? list[0].label : status
      },
      //获取状态字典
      async getDictionary() {
        const res = await this.$http.post(`/api/system/web/dict/dictionary?codes=SOS_STATUS,SOS_TYPE,SKIP_CODE`)
        if (res.status == 200 && res.data.code == 200) {
          this.statusList = res.data.data.SOS_STATUS
          this.typeList = res.data.data.SOS_TYPE
          this.mapList = res.data.data.SKIP_CODE
        }
      },
      //打开告警详情
      openWarningDetail(item) {
        console.log(777)
        this.$http.post("/api/ele/web/sos/getInfo", {
            id: item.id,
            sosTime: item.sosTime ? item.sosTime : ''
          })
          .then((res) => {
            if (res.data.success) {
              res.data.data.sosVideo.forEach((qs) => {
                let url = qs.videoUrl.split('?');
                qs.videoUrl = url[0]
                const urlname = url[0].split('/').pop().split('_')
                qs.videoName = url ? urlname.length > 1 ? `${urlname[1]}_${urlname[2]}` : url[0].split('/')
                  .pop() : ''
                // qs.videoName = url[1].split('&')[0].split('=')[1]
              })
              res.data.data.sosTypeName = item.sosType == -1 ? item.sosText : this.getTypeText(item.sosType)
              res.data.data.sosStatusName = this.sosStatusOptions.find(item => item.value === res.data.data
                  .sosStatus) ? this.sosStatusOptions.find(item => item.value === res.data.data.sosStatus)
                .label : ''
              res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
              res.data.data.speedarr = res.data.data.speedRecord.split(',');

              this.warningDetailsItem.seleId = 1
              this.warningDioDetailsItem = res.data.data
              this.warningDetailVisible = true

            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      //打开风险统计
      openRisk() {
        // this.riskVisible = true
        this.leftEchartsA.on('click', (param) => {
          console.log(param);
          if (param.name == "Ⅳ级风险") {
            this.riskType = 4
          } else if (param.name == 'Ⅲ级风险') {
            this.riskType = 3
          } else if (param.name == 'Ⅱ级风险') {
            this.riskType = 2
          } else if (param.name == 'Ⅰ级风险') {
            this.riskType = 1
          }
          this.riskVisible = true
        })
      },

      mouseSeamlessItem(e, isMouse) {
        const item = e.target.closest(".seamless-row");
        if (item) {
          if (isMouse) {
            item.classList.add('active-item')
          } else {
            item.classList.remove("active-item");
          }
        }
      },
      openseamlessItem(e, type) {
        const item = e.target.closest(".seamless-row");
        if (item) {
          let obj = JSON.parse(item.dataset.obj)
          if (type == 1) {
            this.openRescueDetail(obj)
          } else {
            this.openWarningDetail(obj)
          }
        }
      },

      elevatorTestClose({
        openType
      }) {
        this.elevatorTestVisible = false
        this.$refs.leftBoxB.initTimer()
      },
      faultClose({
        openType
      }) {
        this.faultVisible = false
        if (openType == 2) {
          this.$refs.leftBoxB.initTimer()
        } else if (openType == 3) {
          this.$refs.leftBoxC.initTimer()
        }
      },
      elevatorMaintenanceClose({
        openType
      }) {
        this.elevatorMaintenanceVisible = false
        this.$refs.leftBoxB.initTimer()
      },
      rescueClose({
        openType
      }) {
        this.rescueVisible = false
        if (openType == 2) {
          this.$refs.leftBoxB.initTimer()
        } else if (openType == 3) {
          this.$refs.leftBoxC.initTimer()
        }
      },
      warningClose({
        openType
      }) {
        this.warningVisible = false
        if (openType == 1) {
          this.$refs.leftBoxC.initTimer()
        } else if (openType = 2) {
          this.$refs.rightBoxC.initTimer()
        }
      },
      toManageAreaCode(arr) {
        arr = arr ? JSON.parse(arr) : arr
        let manageAreaCode = (!arr || !arr[0]) ? [] : arr[0]
        return ['', ...manageAreaCode]
      },
      checkBigScreenType() {
        const screenData = JSON.parse(sessionStorage.getItem('adminInfo'))
        let bigScreenType = screenData.bigScreenType

        let routeUrl = ''
        if (screenData.resource[0].children) {
          routeUrl = screenData.resource[0].children[0].routePath
        } else {
          routeUrl = screenData.resource[0].routePath
        }
        if (bigScreenType != 1) {
          this.$router.push(routeUrl)
          // this.$router.push('/dashboard')
        }
      },
      desHander(document) {
        let myChart = echarts.init(document)
        myChart.clear()
      }
    },
    mounted() {
      if (this.butObj.find(item => item == 'BigScreenDetail')) {
        this.isShowDialog = true
      }
      this.checkBigScreenType()
      this.getFacility()
      // this.$nextTick(() => {
      //   openLz();
      // });
      myChart = echarts.init(document.getElementById('myChart'));

      const regionData = {
        name: '中国',
        adcode: 0,
        level: ''
      }
      const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      if (adminInfo.manageAreaCode) {
        let arr = this.toManageAreaCode(adminInfo.manageAreaCode)
        let val = arr.length > 3 ? 2 : arr.length - 1
        regionData.level = arr.length == 1 ? '' : arr.length == 2 ? 'province' : arr.length == 3 ? 'city' : 'district'
        val = arr[val]
        if (val != '') {
          val = val.toString()
          regionData.adcode = val.substring(0, 6)
          regionData.name = adminInfo.areaNames
        }
        if (arr.length > 3) {
          this.showMapGD = true
          this.mapCenterGD = [adminInfo.lng, adminInfo.lat]
          this.mapInitGD()
        }
      }
      this.initRegionData = regionData

      this.getDictionary()
        .then(() => {
          this.pageInit(true)
        })
      this.pageTimer = setInterval(() => {
        this.pageInit()
      }, 60000)

      //绑定点击事件
      this.bindClick()
      //时间计算
      this.timeInit()


      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.fullscreen = false;
      } else {
        this.fullscreen = true;
      }
      // 阻止F11键默认事件，用HTML5全屏API代替
      window.addEventListener('keydown', this.fullScreenKeydown)
      window.addEventListener('resize', this.fullScreenChangeFn)
    },
    beforeDestroy() {
      clearInterval(this.facilityTimer)
      clearInterval(this.timer)
      clearInterval(this.pageTimer)
      clearInterval(this.timerId)
      clearTimeout(this.timeOutEr)
      window.removeEventListener('resize', this.fullScreenKeydown)
      window.removeEventListener('resize', this.fullScreenChangeFn)
    }
  }
</script>

<style>
  @font-face {
    font-family: 'led regular';
    src: url('../../font/Digital-7.ttf');
  }

  .amap-info-content {
    padding: 0px !important;
  }

  .amap-logo {
    right: 0 !important;
    left: auto !important;
    display: none !important;
  }

  .amap-copyright {
    right: 70px !important;
    left: auto !important;
    opacity: 0 !important;
  }
</style>

<style lang="scss" scoped>
  // .ellipsis {
  //   overflow: hidden;
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  // }

  .lodinBack {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 99999;
  }

  .tbdod {
    width: 100%;
    height: 100vh;
    background: url(../../assets/newPanel/lodinbg.png) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .topBgBoxCent {
    // width: 26%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }

  .topBgBox1 {
    display: flex;
    align-items: center;
    width: 35%;
    // height: 100%;
    // background: url(../../assets/topTitright.gif) no-repeat;
    // background-position: center left;
    // background-size: 100% 100%;
  }

  .top-left-img {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }

  .top-right-img {
    flex: 1;
    max-width: calc(100% - 170px);
  }

  .topBgBox2 {
    position: relative;
    width: 35%;
    height: 100%;
    // background: url(../../assets/topTitrleft.gif) no-repeat;
    // background-position: center right;
    // background-size: 100% 100%;
  }

  .topBg {
    min-height: 93px;
    position: absolute;
    z-index: 998;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 1.5vw;
      color: #00AFFF;
      // padding: 0 10px;
      margin-top: 15px;
      background: linear-gradient(0deg, rgba(0, 178, 255, 0.4) 0, #FFFFFF 56%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .top-right {
    // min-width: 214px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 70px;
  }


  .top-time-date {
    color: #fff;
    font-size: 20px;
  }

  .day {
    color: #fff;
    font-size: 20px;
    margin-left: 10px;
  }

  .titBut {
    margin-right: 10px;
  }

  .back-btn {
    width: 144px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    text-align: center;
    color: #fff;
    background: url(../../assets/newBigmianban/btn-bg.png) no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }

  .map-container /deep/ .el-breadcrumb__inner.is-link {
    color: #fff;
  }

  .map-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // width: 38%;
    width: calc(100% - 888px);
    height: 78vh;
  }

  .echarts-container,
  #myChart,
  .mapGD-container,
  #mapGD {
    width: 100%;
    height: 100%;
  }

  .echarts-history {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  #mapGD {
    opacity: 0.92;
  }

  .back-echarts {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
  }

  .left-box {
    position: absolute;
    width: 521px;
    top: 8vh;
    left: 10px;
    height: 90vh;
  }

  .right-box {
    position: absolute;
    width: 521px;
    top: 8vh;
    right: 10px;
    height: 90vh;
  }

  .left-box-a,
  .left-box-b,
  .left-box-c,
  .right-box-a,
  .right-box-b,
  .right-box-c {
    // position: absolute;
    width: 92%;
    height: 30%;
    padding: 1% 4% 2% 4%;
    // background: url(../../assets/tlbg.png) no-repeat;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0);
  }

  .left-box-a {
    background: url(../../assets/newPanel/leftBoxA.png) no-repeat;
    background-position: left top !important;
    background-size: 100% 100% !important;
  }

  .left-box-b {
    background: url(../../assets/newPanel/leftBoxB.png) no-repeat;
    background-position: left top !important;
    background-size: 100% 100% !important;
  }

  .left-box-c {
    background: url(../../assets/newPanel/leftBoxC.png) no-repeat;
    background-position: left top !important;
    background-size: 100% 100% !important;
  }

  .right-box-a {
    background: url(../../assets/newPanel/rightBoxA.png) no-repeat;
    background-position: right top !important;
    background-size: 100% 100% !important;
  }

  .right-box-b {
    background: url(../../assets/newPanel/rightBoxB.png) no-repeat;
    background-position: right top !important;
    background-size: 100% 100% !important;
  }

  .right-box-c {
    background: url(../../assets/newPanel/rightBoxC.png) no-repeat;
    background-position: right top !important;
    background-size: 100% 100% !important;
  }

  .leftBoxCBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .leftBoxCImg {
    position: relative;
    margin-top: 20px;
    color: #fff;
    text-align: center;
  }

  .leftBoxCNum {
    position: absolute;
    bottom: 20px;
    width: 100%;
  }

  .left-box-a,
  .right-box-a {
    top: 0;
  }

  #left-box-a {
    height: 100%;
    margin-top: 0%;
  }

  .left-box-b,
  .right-box-b {
    // top: 34%;
    margin: 20px 0;
  }

  .left-box-c,
  .right-box-c {
    top: 68%;
  }

  #left-box-c {
    background: url(../../assets/newBigmianban/base.png) no-repeat;
    background-size: 408px 31px;
    background-position: center 70%;
  }

  .box-img {
    // width: 100%;
    // height: 40px;
    // display: flex;
    // align-items: center;
    // background: url(../../assets/newPanel/msgTit.png) no-repeat;
    // background-position: bottom center;
    // background-size: contain;
  }

  .titleBg {
    margin-top: -10px;
    width: 100%;
  }

  .box-img span {
    // color: white;
    // font-size: 18px;
    // margin-left: 10px;
    margin-bottom: 5px;
    background: linear-gradient(0deg, #76C0FF 0%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;
    font-size: 20px;
  }

  .chart {
    width: 100%;
    height: 82%;
    margin-top: 4%;
    overflow: hidden;
  }


  .right-a-list-container,
  .right-b-list-container {
    height: 100%;
    overflow: hidden;
  }

  .right-a-list,
  .right-b-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .right-b-list-left {
    display: flex;
    flex: 3;
    align-items: center;
    background: #9f596b8c;
    border: 1px solid #FF2020;
    // border: 1px dashed #d58b8b;
    border-radius: 5px;
    padding: 5px;
  }


  .right-a-item,
  .right-b-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    cursor: pointer;
  }

  // .right-a-item{
  // 	margin-bottom: 3%;
  // 	padding: 1%;
  // }
  .right-a-item {
    margin-bottom: 10px;
    padding: 0.5% 1%;
    background: #13416B;
  }

  .right-b-item {
    margin-bottom: 10px;
    padding: 1%;
    background: #13416B;
  }

  .active-item {
    background: #4d74e066;

  }

  .sos {
    min-width: 58px;
    height: 58px;
    line-height: 58px;
    font-size: 23px;
    text-align: center;
    background: url(../../assets/newBigmianban/sos.gif) no-repeat;
    background-position: bottom center;
    background-size: contain;
  }

  .right-a-title-container,
  .right-b-title-container {
    flex: 5;
    margin: 0 18px;
    color: #fff;
  }

  .right-a-title {
    // max-width: 220px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
  }

  .right-b-title {
    max-width: 362px;
  }

  .right-a-time-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
    flex-direction: column;
  }

  .right-a-img {
    margin-right: 4px;
  }

  .right-a-date,
  .right-a-time,
  .right-a-status,
  .right-b-date {
    font-size: 12px;
  }

  .right-a-status {
    width: 53px;
    height: 28px;
    line-height: 28px;
    border-radius: 4px;
    text-align: center;
  }

  .rescue-status-1 {
    .right-a-title-container {
      color: #FF7070;
    }

    .right-a-time {
      color: #FF0000;
      margin-top: 5px;
    }

    .right-a-status {
      background-color: #FF0000;
    }
  }

  .rescue-status-2 {
    .right-a-title-container {
      color: #FF7070;
    }

    .right-a-time {
      color: #FF0000;
    }

    .right-a-status {
      background-color: #FF7070;
    }
  }

  .rescue-status-3 {
    .right-a-title-container {
      color: #fff;
    }

    .right-a-time {
      color: #02AF90;
      margin-top: 5px;
    }

    .right-a-status {
      background-color: #02AF90;
    }
  }

  .right-b-img {
    width: 27px;
    height: 27px;
    margin: 5px;
  }

  .right-b-status {
    // width: 53px;
    padding: 0 6px;
    // height: 28px;
    // line-height: 28px;
    // text-align: center;
    border-radius: 4px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    min-width: 60px
  }

  .right-b-title {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
  }

  .fault-status-4 {
    .right-b-status {
      background-color: #03a18d;
    }
  }

  .fault-status-5 {

    .right-b-status {
      background-color: #ff0000;
    }
  }

  .fault-status-7 {
    .right-b-status {
      background-color: #ffa0a0;
    }
  }

  .fault-status-8 {
    .right-b-status {
      background-color: #00cf3a;
    }
  }

  .fault-status-9 {
    .right-b-status {
      background-color: #0090ff;
    }
  }

  .fault-status-10 {
    .right-b-status {
      background-color: #046ebf;
    }
  }

  .fault-status-11 {
    .right-b-status {
      background-color: #ff6000;
    }
  }

  .fault-status-12 {
    .right-b-status {
      background-color: #ff32f8;
    }
  }

  .fault-status-13 {
    .right-b-status {
      background-color: #ff6262;
    }
  }

  .fault-status-95 {
    .right-b-status {
      background-color: #2ecbff;
    }
  }

  .right-b-item {
    margin-bottom: 3%;
  }

  .footer {
    position: absolute;
    left: 50%;
    bottom: 4%;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 1082px);
    max-width: 841px;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 0 4px 28px;
    // background: rgba(28, 28, 29, 0.75);
  }

  .footer-item {
    display: flex;
    flex: 1;
    max-width: 130px;
    flex-direction: column;
    text-align: center;
    color: #fff;
    margin: 0 4px;
    cursor: pointer;
  }

  .footer-num {
    width: 100%;
    font-size: 2vw;
    font-weight: bolder;
    // font-family: 'led regular';
    // margin-right: 2px;
    position: absolute;
    top: 25%;
    left: 0%;
    text-align: center;
  }

  .footer-num0 {
    color: #28B5AB;
    // -webkit-text-stroke: 1px #d9d9d9;
  }

  .footer-num1 {
    color: #E89701;
    // -webkit-text-stroke: 1px #d9d9d9;
  }

  .footer-num2 {
    color: #DF0901;
    // -webkit-text-stroke: 1px #d9d9d9;
  }

  .footer-num3 {
    color: #F4C84F;
    // -webkit-text-stroke: 1px #d9d9d9;
  }

  .footer-num4 {
    color: #00FFFD;
    // -webkit-text-stroke: 1px #d9d9d9;
  }

  .footer-num-container {
    // max-width: 100px;
    margin: 0px auto;
  }

  .footer-img-container {
    width: 100%;
    position: relative;
  }

  .footer-img {
    width: 100%;
  }

  .footer-text {
    margin-top: 8px;
    font-size: 14px;
    font-weight: bolder;
    margin-bottom: -20px;
    z-index: 10
  }

  .full-Screen {
    width: 20px;
    height: 20px;
    background: url(../../assets/newPanel/screen-2.png) no-repeat;
    background-position: center;
    background-size: 100% 100%;
    cursor: pointer;
    margin: 0 11px;
  }

  .close-full-Screen {
    background: url(../../assets/newPanel/screen-1.png) no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .map-icon-box {
    position: absolute;
    right: 10%;
    bottom: 130px;
    display: flex;
    flex-direction: column;
  }

  .map-icon {
    width: 32px;
    height: 32px;
    margin-top: 4px;
    cursor: pointer;
  }

  .null-list {
    margin-top: 60px;
    text-align: center;
    color: #fff;
  }
</style>

<style lang="scss">
  @import './css/index.scss';
</style>
